import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "notificationPrompt", "messageBlock", "requestAuthorizationText", "deniedAuthorizationText" ];
  static values = {
    ios: Boolean,
    iosTokenPresent: Boolean,
  };

  async connect() {
    if (typeof Android !== 'undefined') {
      this._hideNotificationBlock();
    } else if (this.iosValue) {
      this._hideNotificationBlock();
      document.addEventListener("show_notification_block", this._showNotificationBlock.bind(this));
      document.addEventListener("hide_notification_block", this._hideNotificationBlock.bind(this));
      document.addEventListener("register_token", this._registerIosToken.bind(this));
      if (this.iosTokenPresentValue != true) {
        console.log("asking to enable notif");
        window.webkit.messageHandlers.checkNotif.postMessage("");
      }
    } else {
      if (navigator.serviceWorker) {
        if (Notification.permission == "denied") {
          this._showDeniedAuthorizationText();
          this._hideRequestAuthorizationText();
          return;
        }
        const currentSubscription = await this._currentSubscription();

        console.debug("[NotificationController]", "Notification.permission :", Notification.permission, ", currentSubscription :", currentSubscription);

        if (currentSubscription != null) {
          this._confirmSubscriptionOnServer(currentSubscription);
          this._hideNotificationBlock();
        } else if (Notification.permission == "default" || currentSubscription == null) {
          this._showNotificationBlock();
        } else {
          this._hideNotificationBlock();
        }
      } else {
        this._hideNotificationBlock();
      }
    }
  }

  disconnect() {
    if (this.iosValue) {
      document.removeEventListener("show_notification_block", this._showNotificationBlock.bind(this));
      document.removeEventListener("hide_notification_block", this._hideNotificationBlock.bind(this));
      document.removeEventListener("register_token", this._registerIosToken.bind(this));
    }
  }

  subscribeToNotifications(event) {
    event.preventDefault();

    if (this.iosValue) {
      console.log("trigger subscribeToNotifications");
      window.webkit.messageHandlers.enableNotif.postMessage("");
      console.log("subscribeToNotifications end");
    } else {
      navigator.serviceWorker?.ready?.then((registration) => {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.settings.vapid_public
        }).then((subscription) => {
          this._sendSubscriptionToBackEnd(subscription);
          this._hideNotificationBlock();
        });
      });
    }
  }

  _toggleNotificationBlock(visible = true) {
    this.notificationPromptTarget.hidden = !visible;
    if (this.hasMessageBlockTarget) {
      this.messageBlockTarget.hidden = visible;
    }
  }

  _showNotificationBlock() {
    this.notificationPromptTarget.hidden = false;
    if (this.hasMessageBlockTarget) {
      this.messageBlockTarget.hidden = true;
    }
  }

  _hideNotificationBlock() {
    this.notificationPromptTarget.hidden = true;
    if (this.hasMessageBlockTarget) {
      this.messageBlockTarget.hidden = false;
    }
  }

  _showDeniedAuthorizationText() {
    this.deniedAuthorizationTextTarget.hidden = false;
  }

  _hideRequestAuthorizationText() {
    this.requestAuthorizationTextTarget.hidden = true;
  }

  _registerIosToken(event) {
    if (!this.iosValue) { return; }

    console.log("start register ios token");
    this._performPostToSubscriptions({
      device_token: event.detail,
      authenticity_token: settings.token
    }).then(function (response) {
      if (response.ok) {
        console.log("ios token registered");
        this._hideNotificationBlock();
      } else {
        this._showNotificationBlock();
      }
    });
  }

  _sendSubscriptionToBackEnd(subscription) {
    this._performPostToSubscriptions(subscription)
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Bad status code from server.");
        }

        return response.json();
      }).then(function (responseData) {
        if (!(responseData.data && responseData.data.success)) {
          throw new Error("Bad response from server.");
        }
      });
  }

  _performPostToSubscriptions(params) {
    return fetch("/subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify(params)
    });
  }

  _confirmSubscriptionOnServer(subscription) {
    this._sendSubscriptionToBackEnd(subscription);
  }

  async _currentSubscription() {
    const sw = await navigator.serviceWorker.ready;
    return sw.pushManager.getSubscription();
  }
}
