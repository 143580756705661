import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["activeEvent"]

  clicked() {
    Turbo.visit(this._event_url_to_visit(), { frame: "dashboard_modal"})
  }

  _event_url_to_visit(){
    if (this.hasActiveEventTarget) {
      return this.activeEventTargets.at(0).getAttribute("data-event-link")
    }
  }
}
