import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'menu', 'icon' ]

  connect() {
    this.hidden = true
  }

  toggle() {
    if (this.hidden) {
      this.menuTarget.style.display = 'block'
      this.menuTarget.style.opacity = 1
      if (this.hasIconTarget) {
        this.iconTarget.classList.remove('fa-chevron-down')
        this.iconTarget.classList.add('fa-chevron-up')
      }
      this.hidden = false
    } else {
      this.menuTarget.style.display = 'none'
      this.menuTarget.style.opacity = 0
      if (this.hasIconTarget) {
        this.iconTarget.classList.remove('fa-chevron-up')
        this.iconTarget.classList.add('fa-chevron-down')
      }
      this.hidden = true
    }
  }
}
