import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['nameField', 'valueField', 'channelTypeField', 'userField']

  selectChannelType() {
    const channelType = this.channelTypeFieldTarget.selectedOptions[0].value
    history.replaceState(history.state, '', `?escalation_channel[channel_type]=${channelType}`)
    this.element.src = window.location.href
  }

  populateAvailableUsers() {
    const [username, value] = this.userFieldTarget.selectedOptions[0].textContent.split(' - ')
    this.nameFieldTarget.value = username
    this.nameFieldTarget.setAttribute('readonly', true)
    if (value) {
      this.valueFieldTarget.value = value
      this.valueFieldTarget.setAttribute('readonly', true)
    }
  }
}
