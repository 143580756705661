import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ "element" ]
    static classes = [ "selected" ]

  selected(event) {
    this.elementTargets.forEach((element) => {
      element.classList.remove(...this.selectedClasses)
    })
    event.currentTarget.classList.add(...this.selectedClasses)
  }
}
