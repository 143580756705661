import { Controller } from '@hotwired/stimulus'
import * as Turbo from "@hotwired/turbo"

// needed to support forms submitted inside a turbo_frame
// when the form is invalid we want the form to be rendered inside the turbo_frame
// when the form is valid we want to follow the redirect
// adding a data-turbo-frame="_top" to the form will always follow the redirect,
// and render invalid form in there onwn page, not replacing the existing frame.
export default class extends Controller {
  connect() {
    console.log("connected")
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
  }

  next(event) {
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }
}
