import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "image" ];

  static values = {
    displaySticks: Boolean,
    buttonDisplayText: String,
    buttonHideText: String,
  };

  imageTargetConnected(image) {
    image.setAttribute("data-device-capture--canvas-display-sticks-value", this.displaySticksValue);
  }

  toggle(event){
    event.preventDefault();
    this.displaySticksValue = !this.displaySticksValue;
    if(this.displaySticksValue){
      event.target.innerText = this.buttonHideTextValue;
    } else {
      event.target.innerText = this.buttonDisplayTextValue;
    }
    this.imageTargets.forEach(image => {
      image.setAttribute("data-device-capture--canvas-display-sticks-value", this.displaySticksValue);
    });
  }
}
