import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "icon"]
  static classes = [ "visibleIcon", "invisibleIcon" ]

  revealPassword() {
    if(this.inputTarget.type == "text") {
      this.inputTarget.type = "password";
      this.iconTarget.classList.replace(this.visibleIconClass, this.invisibleIconClass)
    } else {
      this.inputTarget.type = "text";
      this.iconTarget.classList.replace(this.invisibleIconClass, this.visibleIconClass)
    }
  }
}
