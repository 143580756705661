import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "timeRangeSelectionSection", "startTimeInput", "endTimeInput" ]
    static values = { allTheTime: Boolean }

    allTheTimeSelected(e) {
        this.allTheTimeValue = true
        this.startTimeInputTarget.value = '00:00'
        this.endTimeInputTarget.value = '23:59'
    }
    specificTimeRangeSelected(e) {
        this.allTheTimeValue = false
    }

    allTheTimeValueChanged() {
        this.timeRangeSelectionSectionTarget.hidden = this.allTheTimeValue
    }
}
