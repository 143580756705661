import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'

export default class extends Controller {
  static targets = []
  static values = { url: String }

  connect() {
    this._ready()
  }

  onSubmit(event) {
    event.preventDefault()
  }

  async change(event) {
    if (!event.target.checked){
      event.target.checked = true
      event.preventDefault()
      return
    }

    const device_capture_param = {}
    device_capture_param[`flag_for_annotation_${event.params.camera}`] = true
    const flagging = { device_capture: device_capture_param }

    this._waiting()
    const response = await put(this.urlValue, { body: JSON.stringify(flagging) })
    if (!response.ok) {
      alert('Error Flagging For Annotation')
    }
    this._ready()
  }

  _waiting() {
    this.waiting = true
    this.element.classList.add('cursor-wait')
  }

  _ready() {
    this.waiting = false
    this.element.classList.remove('cursor-wait')
  }
}
