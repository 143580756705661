import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "uploadedFileName" ]

  display_filename(event) {
    this.uploadedFileNameTarget.textContent = this._filename(event.target)
  }

  _filename(fileField) {
    return fileField.files[0].name
  }
}
