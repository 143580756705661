import BluetoothController, { factoryResetCharacteristicUUID} from "../bluetooth_controller";
import { notifyServerOnFactoryResetSuccess } from "../api";
import showAlert from "../../../src/alerts";
export default class extends BluetoothController {
  static targets = ["actionIcon", "spinnerIcon", "resetButton"];

  static values = {
    successfulText: String,
    unsuccessfulText: String,
    requestData: String,
    confirmationText: String,
    deviceSecureCode: String,
    completedUrl: String,
  };

  connect() {
    super.connect();
    document.addEventListener("factory_reset_response", this.handleBluetoothResponseFromIosApp.bind(this));
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener("factory_reset_response", this.handleBluetoothResponseFromIosApp.bind(this));
    window.webkit.messageHandlers.disableBluetooth.postMessage("");
  }

  factoryReset() {
    this.startSpinner();
    const confirmed = confirm(this.confirmationTextValue);
    if (!confirmed) {
      this.stopSpinner();
      return;
    }

    try {
      const command = {
        description: "factoryReset",
        "characteristicUUID": factoryResetCharacteristicUUID,
        "value": this.requestDataValue,
        "secureCode": this.deviceSecureCodeValue,
      };
      window.webkit.messageHandlers.enableBluetooth.postMessage(command);
    } catch (error) {
      showAlert(this.unsuccessfulTextValue);
      this.stopSpinner();
    }
  }

  hideResetButton() {
    this.resetButtonTarget.classList.add("display-hidden");
  }

  handleBluetoothResponseFromIosApp(event) {
    this.stopSpinner();
    if (event.detail === this.requestDataValue) {
      this.hideResetButton();
      notifyServerOnFactoryResetSuccess(this.completedUrlValue);

      showAlert(this.successfulTextValue);
    } else {
      showAlert(this.unsuccessfulTextValue);
    }
  }
}
