import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "dayCheckbox", "daysSelectionSection" ]
    static values = { everyday: Boolean }

    everydaySelected(e) {
        this.everydayValue = true
        this.dayCheckboxTargets.forEach((element, index) => {
            element.checked = true
        });
    }
    someDaysSelected(e) {
        this.everydayValue = false
    }

    everydayValueChanged() {
        this.daysSelectionSectionTarget.hidden = this.everydayValue
    }
}
