import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    enableTimeline: Boolean,
    deviceType: String,
  }

  connect() {
    let ratio = { x: 16, y: 9 }
    if (this.deviceTypeValue == 'fisheye') {
      ratio = { x: 1, y: 1 }
    }
    initTimeline(this.element, this.enableTimelineValue, ratio)
  }

  disconnect() {
    this.stop()
  }

  stop() {
    clearInterval(window.timelineRefreshInterval)
  }
}
