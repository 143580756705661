import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "step", "formButtons", "stepButtons", "prevButton", "nextButton", "stepIcon", "stepIconSection", "stepText" ]
    static values = { currentStep: Number, enabled: Boolean }
    static classes = [ 'activeIcon', 'inactiveIcon' ]

    connect() {
        if(!this.enabledValue) {
            this.nextButtonTarget.hidden = true
            this.prevButtonTarget.hidden = true
            this.stepButtonsTarget.hidden = true
            this.stepIconSectionTarget.hidden = true
            this.stepTextTargets.forEach((element, index) => {
                element.hidden = true
            })
        }
    }

    next(e) {
        this.currentStepValue++
    }

    previous(e) {
        this.currentStepValue--
    }

    currentStepValueChanged() {
        if(this.enabledValue) {
            this.showCurrentStep()
            this.showFormButtons()
            this.showStepButtons()
        }
    }

    showCurrentStep() {
        this.stepTargets.forEach((element, index) => {
            element.hidden = index != this.currentStepValue
        })
        this.stepIconTargets.forEach((element, index) => {
            if (index == this.currentStepValue) {
                element.classList.add(...this.activeIconClasses)
                element.classList.remove(...this.inactiveIconClasses)
            } else {
                element.classList.add(...this.inactiveIconClasses)
                element.classList.remove(...this.activeIconClasses)
            }
        })
    }

    showFormButtons() {
        this.formButtonsTarget.hidden = !this._isLastStep()
    }

    showStepButtons() {
        this.nextButtonTarget.hidden = this._isLastStep()
        this.prevButtonTarget.hidden = this._isFirstStep()
    }

    _isFirstStep() {
        return this.currentStepValue == 0
    }

    _isLastStep() {
        return this.currentStepValue == (this.stepTargets.length - 1)
    }
}
