import { Controller } from '@hotwired/stimulus';
import { patch, put, post, destroy } from '@rails/request.js';

export default class extends Controller {

  static values = {
    method: String,
    reloadOnSubmit: Boolean,
  };

  async execute(e) {
    e.preventDefault()

    let response;

    switch (this.methodValue) {
      case 'post':
        response = await post(this.element.href);
        break;
      case 'put':
        response = await put(this.element.href);
        break;
      case 'patch':
        response = await patch(this.element.href);
        break;
      case 'delete':
        response = await destroy(this.element.href);
        break;
      default:
        throw new Error(`Unsupported HTTP Method: ${this.methodValue}`);
    }
    if (response.ok) {
      if (this.reloadOnSubmitValue) {
        location.reload()
        return
      }
    }
  }
}
