import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "toggle" ]

  toggle() {
    this.toggleClasses.forEach((class_name) => {
      this.element.classList.toggle(class_name)
    })
  }
}
