import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'email', 'refreshButton', 'updateActionButton']

  connect() {
    document.addEventListener('turbo:frame-load', () => {
      if (this.hasUpdateActionButtonTarget) {
        this.updateActionButtonTarget.click()
      }
    })
  }

  refreshForm() {
    this.emailTargets.forEach((emailTarget) => {
      if (emailTarget.value == this.inputTarget.value) {
        this.refreshButtonTarget.click()
        return
      }
    })
  }
}
