import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['securityType', 'wifiSsid', 'wifiPassword', 'wifiUsername', 'destroy']

    connect() {
      this.setSecurity()
    }

    setSecurity() {
      switch(this.securityTypeTarget.value) {
        case 'security_none':
          this.destroyTarget.value = false;
          this.wifiSsidTarget.hidden = false;
          this.wifiPasswordTarget.hidden = true;
          this.wifiUsernameTarget.hidden = true;
          break;
        case 'wpa_psk':
          this.destroyTarget.value = false;
          this.wifiSsidTarget.hidden = false;
          this.wifiPasswordTarget.hidden = false;
          this.wifiUsernameTarget.hidden = true;
          break;
        case 'wpa_eap':
          this.destroyTarget.value = false;
          this.wifiSsidTarget.hidden = false;
          this.wifiPasswordTarget.hidden = false;
          this.wifiUsernameTarget.hidden = false;
          break;
        default:
          this.destroyTarget.value = true;
          this.wifiPasswordTarget.hidden = true;
          this.wifiUsernameTarget.hidden = true;
          this.wifiSsidTarget.hidden = true;
      }
    }
}
