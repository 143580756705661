// https://stimulus.hotwired.dev
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["message"];

  clicked() {
    this.messageTarget.focus()
  }
}
