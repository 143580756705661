import dayjs from 'dayjs';

if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
    .then(function(reg) {
        var blurred = false;
        var last_update = dayjs();
        window.onblur = function() { blurred = true; };
        window.onfocus = function() {
            if (blurred == true) {
                blurred = false;
                if (dayjs().diff(last_update) >= 600000) {
                    last_update = dayjs();
                    Turbo.visit(location.toString());
                };
            }
        };
      console.log('[Companion]', 'Service worker registered!');
    });
}
