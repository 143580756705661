document.addEventListener('turbo:load', function () {
  var button, form, handleClearForm, isMobile, select;
  form = $('.simple_form');
  select = form.find('.js-select2');
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (!isMobile) {
    return;
  }
  select.each(function (_, item) {
    var minimumSearch;
    minimumSearch = $(item).attr('data-minimum-search');
    return $(item).select2({
      theme: 'bootstrap4',
      dropdownAutoWidth: false,
      minimumResultsForSearch: minimumSearch
    });
  });
  if (navigator.userAgent.match('iPad|iPhone|iPod') !== -1) {
    select.addClass('iOSselect');
    select.parent('div').addClass('select-container');
  }
  handleClearForm = function () {
    event.preventDefault();
    form = $(this).parents('.simple_form');
    return $(form).trigger('reset');
  };
  button = form.find('.js-clear-form')[0];
  if (typeof button !== 'undefined') {
    return button.addEventListener("click", handleClearForm, false);
  }
});