import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    endTime: String
  };

  static targets = ['resendButton']

  connect() {
    if (!this.endTimeValue) { return; }

    this.resendMessage = this.resendButtonTarget.innerHTML;
    this.endTime = new Date(this.endTimeValue);

    if (Date.now() < this.endTime) {
      this.updateCountdown();

      this.timer = setInterval(() => {
        this.updateCountdown();
      }, 1000);
    }
  }

  updateCountdown() {
    const seconds = Math.ceil((this.endTime - Date.now()) / 1000);

    if (seconds > 0) {
      this.resendButtonTarget.disabled = true;
      this.resendButtonTarget.innerHTML = `${this.resendMessage} | ${seconds} s`;
    } else {
      this.stopTimer();
      this.resendButtonTarget.disabled = false;
      this.resendButtonTarget.innerHTML = this.resendMessage;
    }
  }

  stopTimer() {
    if (!this.timer) { return; }

    clearInterval(this.timer);
  }

  disconnect() {
    this.stopTimer();
  }
}
