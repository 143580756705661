import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['refreshButton']

  connect() {
    console.log(this.element)
  }

  refresh() {
    this.refreshButtonTarget.click()
  }
}

