import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect() {
    if (typeof Android === 'undefined') {
      Cookies.remove('nobi_android_app')
      return
    }

    Cookies.set('nobi_android_app', 'NobiAndroidApp')
  }
}
