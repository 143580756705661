import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (typeof Android === 'undefined') {
      return
    }

    Android.androidSubscriptionStatusChange = (event) => {
      const authToken = document.querySelector("meta[name=csrf-token]").content
      if (event.detail.enabled) {
        fetch('/subscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': authToken
            },
            body: JSON.stringify({
              device_token: event.detail.token,
              device_uid: event.detail.device_uid,
              type: 'fcm',
              version: event.detail.version,
            })
        })
      } else {
        fetch(`/subscriptions/fcm/${event.detail.device_uid}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authToken
          }
        })
      }
    }

    Android.promptNotifications()
  }
}
