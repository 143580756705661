import BluetoothController, { onboardServiceUUID, onboardCharacteristicUUID, } from "../bluetooth_controller"
import showAlert from "../../../src/alerts"
import base64ToArrayBuffer from "../../../src/base64"
import { decodeBluetoothResponse } from "../api"

export default class extends BluetoothController {
  static targets = ["actionIcon", "spinnerIcon", "linkBlock"]
  static values = {
    requestData: String,
    enableBluetoothMessage: String,
    responseUrl: String,
    redirectToOnError: String,
    deviceSecureCode: String,
  }

  async bluetoothConnect() {
    if (!navigator) {
      showAlert(this.enableBluetoothMessageValue)
      return
    }

    const decoder = new TextDecoder("utf-8")
    this.startSpinner()

    try {
      const device =  await navigator.bluetooth.requestDevice(
        this.deviceFilters()
      )

      window.bluetooth_device_name = device.name
      const server = await device.gatt.connect()
      const service = await server.getPrimaryService(onboardServiceUUID)
      const characteristic = await service.getCharacteristic(onboardCharacteristicUUID)
      await characteristic.writeValue(base64ToArrayBuffer(this.requestDataValue))

      const readAndDecodeCharacteristic = async () => {
        this.lampConfigurationStarted()
        let value = await characteristic.readValue()
        this.linkBlockTarget.classList.add("display-hidden")

        const response = window.btoa(decoder.decode(value))
        decodeBluetoothResponse(response, this.responseUrlValue, device)
      }

      return window.stagingDeviceLoopId = setInterval(readAndDecodeCharacteristic, 1000)
    } catch (error) {
      this.stopSpinner()
      console.log(error)
      if (error == ~/User cancelled the requestDevice\(\) chooser/) {
        window.location.href = this.redirectToOnErrorValue
      } else {
        showAlert(error)
        setTimeout(() => window.location.href = this.redirectToOnErrorValue, 3000)
      }
    }
  }
}
