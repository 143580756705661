import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { currentLocale: String, supportedLanguages: Array }

  connect() {
    this.detect()
  }

  select() {
    history.replaceState(history.state, '', `?lang=${this.element.value}`)
    const src = window.location.href
    const translatableContent = document.getElementById('translatable_content')
    translatableContent.setAttribute('src', src)
  }

  detect() {
    const browserLanguageWithRegion = navigator.language
    const browserLanguage = navigator.language.split('-')[0]
    const isBrowserLanguageWithRegionSupported = this.supportedLanguagesValue.includes(browserLanguageWithRegion)
    const isBrowserLanguageSupported = this.supportedLanguagesValue.includes(browserLanguage)

    if (!window.location.search.includes('lang')) {
      if (isBrowserLanguageWithRegionSupported && !this._languageAlreadySet(browserLanguageWithRegion)) {
        this.element.value = browserLanguageWithRegion
        this.element.dispatchEvent(new Event('change'))
        return
      }

      if (isBrowserLanguageSupported && !this._languageAlreadySet(browserLanguage)) {
        this.element.value = browserLanguage
        this.element.dispatchEvent(new Event('change'))
        return
      }
    } else {
      this.element.value = this.currentLocaleValue
    }
  }

  _languageAlreadySet(lang) {
    return this.element.value == lang && this.currentLocaleValue == this.element.value
  }
}
