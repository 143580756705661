import { Controller } from "@hotwired/stimulus";
import { debounce } from "helpers/timing_helpers"

export default class extends Controller {
  static targets = [ "urgentZone", "techAlertZone", "attentionZone", "analysisZone", "hiddenZone", "housingUnitCard", "housingUnitCardEvent" ]

  // When a card is connected it can trigger a refresh, when a card is moved it triggers a refresh, when an event is dispatched on the card it triggers a refresh
  // Using debounce ensures the view is refreshed at most twice, once on first display, second time after the refresh has occured and targets are reconnected.
  initialize() {
    this.refreshPriorityView = debounce(this.refreshPriorityView.bind(this), 200)
  }

  housingUnitCardTargetConnected(element){
    this.refreshPriorityView()
  }

  housingUnitCardEventTargetConnected(){
    this.refreshPriorityView()
  }

  housingUnitCardEventTargetDisconnected(){
    this.refreshPriorityView()
  }

  refreshPriorityView() {
    this.#refreshPriorityViewDebounced()
  }

  #refreshPriorityViewDebounced(){
    console.debug("_refreshPriorityView")
    this.housingUnitCardTargets.forEach((housingUnitCard) => {
      if (housingUnitCard.querySelector(":has(.nobi-priority-1[data-active-event])")) {
        if (!this.urgentZoneTarget.contains(housingUnitCard)){
          this.urgentZoneTarget.prepend(housingUnitCard)
        }
      } else if (housingUnitCard.querySelector(":has(.nobi-priority-2[data-active-event])")) {
        if (!this.techAlertZoneTarget.contains(housingUnitCard)){
          this.techAlertZoneTarget.prepend(housingUnitCard)
        }
      } else if (housingUnitCard.querySelector(":has(.nobi-priority-3[data-active-event])")) {
        if (!this.attentionZoneTarget.contains(housingUnitCard)){
          this.attentionZoneTarget.prepend(housingUnitCard)
        }
      } else if (housingUnitCard.querySelector(":has(.nobi-informative-1[data-active-event])")) {
        if (!this.analysisZoneTarget.contains(housingUnitCard)){
          this.analysisZoneTarget.prepend(housingUnitCard)
        }
      } else {
        this.hiddenZoneTarget.prepend(housingUnitCard)
      }
    })
  }
}
