import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "output" ]

  blink() {
    $.ajax({
      url: '/devices/' + event.target.value + '/blink',
      type: 'patch'
    });
  }
}
