import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    postUrl: String,
    redirectUrl: String,
  }

  connect() { }

  openDialog(){
    ActiveAdmin.ModalDialog(
      'Send Logs (UTC)',
      { from: 'text', to: 'text', include_system: 'checkbox' },
      (inputs) => {
        document.body.style.cursor = 'wait'
        fetch(`${this.postUrlValue}?from=${inputs.from}&to=${inputs.to}&include_system=${inputs.include_system}`).then(response => {
          document.body.style.cursor = 'default'
          // Check if response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          window.location.href = this.redirectUrlValue
        })
      }
    );
    setTimeout(this._initDateTimePickers, 300);
  }

  _initDateTimePickers() {
    const modal = document.getElementById('dialog_confirm')
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
      timeZone: 'UTC'
    }

    modal.querySelectorAll('input[name="from"],input[name="to"]').forEach(input => {
      input.classList.add('date-time-picker')
      input.setAttribute('maxlength', 19)
      input.setAttribute('data-datepicker-options', JSON.stringify({ format: "d/m/Y H:i" }))
      if (input.name == 'from') {
        const oneHourAgo = new Date(new Date().getTime() - (1 * 60 * 60 * 1000))
        input.value = oneHourAgo.toLocaleString('en-GB', options).replace(',', '')
      }
    });

    // Hack for rspec/capybara for global scope functions
    if (typeof(setupDateTimePicker) === 'function') {
      setupDateTimePicker(modal)
    }
  }
}
