const showAlert = (message) => {
  let notify = $.notify(
    { message: message },
    {
      type: "alert",
      allow_dismiss: true,
      placement: {
        align:"center"
      },
      animate: {
        enter: "animated bounce",
        exit: "animated bounce"
      }
    }
  );
  document.addEventListener("turbo:before-visit", () => { notify.close() }, { once: true });
  document.addEventListener("turbo:before-cache", () => { notify.$ele.remove() }, { once: true });
};

// set it as window object
const nobi = window.nobi || {};
nobi.showAlert = showAlert;

// export as function
export default showAlert;

