import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.showModal()
  }

  handleClick(event) {
    if (event.target === this.element) {
      this.element.remove()
    }
  }

  close() {
    this.element.remove()
  }
}
