import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = []

    goBack(e) {
      e.preventDefault();
      if (navigator.userAgent.includes('nobi-ios')) {
        window.webkit.messageHandlers.back.postMessage('');
      } else
      {
        javascript:history.back();
      }
    }

    goDoubleBack(e) {
      e.preventDefault();
      javascript:history.go(-3);
    }
}
