import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    endTime: String,
    refresh: Boolean
  };

  static targets = [
    "timeLeft"
  ];

  connect() {
    if (!this.endTimeValue) { return; }

    this.endTime = new Date(this.endTimeValue);

    if (Date.now() < this.endTime) {
      this.updateCountdown();

      this.timer = setInterval(() => {
        this.updateCountdown();
      }, 1000);

    } else {
      // if timer is already expired, reload the page
      setTimeout(() => window.location.reload(), 500);
    }
  }

  updateCountdown() {
    const seconds = Math.ceil((this.endTime - Date.now()) / 1000);

    if (seconds > 0) {
      this.timeLeftTarget.innerHTML = seconds;
    } else {
      this.stopTimer();
      if (this.refreshValue) {
        window.location.reload();
      }
    }
  }

  stopTimer() {
    if (!this.timer) { return; }

    clearInterval(this.timer);
  }

  disconnect() {
    this.stopTimer();
  }
}
