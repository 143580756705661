import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { siteKey: String }

  initialize() {
    if (this.siteKeyValue) {
      grecaptcha.render('recaptchaV2', { sitekey: this.siteKeyValue } )
    }
  }
}
