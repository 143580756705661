import { Controller } from '@hotwired/stimulus';
import Glide from '@glidejs/glide'

export default class extends Controller {
  static targets = ["carousel"];

  connect() {
    new Glide(this.carouselTarget).mount();
  }
}
