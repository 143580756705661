import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['informedConsent', 'nextButton']

  nextButtonTargetConnected() {
    this.toggleNextButton()
  }

  toggleNextButton() {
    let nextButton

    if (this.hasNoInformedConsentTarget) {
      nextButton = this.nextButtonTarget
    } else {
      nextButton = document.querySelector("[data-personal-data-form-target='nextButton']")
    }

    if (this.informedConsentTarget.checked) {
      nextButton.disabled = false
    } else {
      nextButton.disabled = true
    }
  }
}
