import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  CONNECTIONS = [[1, 2], [1, 5], [2, 3], [3, 4], [5, 6], [6, 7], [1, 8], [8, 9], [9, 10], [1, 11], [11, 12], [12, 13], [1, 0], [0, 14], [14, 16], [0, 15], [15, 17]];

  POSECOLORS = ["rgb(255,0,85)", "rgb(255,0,0)", "rgb(255,85,0)", "rgb(255,170,0)", "rgb(255,255,0)",
    "rgb(170,255,0)", "rgb(85,255,0)", "rgb(0,255,0)", "rgb(0,255,85)", "rgb(0,255,170)",
    "rgb(0,255,255)", "rgb(0,170,255)", "rgb(0,85,255)", "rgb(0,0,255)", "rgb(255,0,170)",
    "rgb(170,0,255)", "rgb(255,0,255)", "rgb(85,0,255)"];

  static targets = [ "canvas" ];
  static values = {
    scaleRatio: Number,
    posesCoordinates: Array,
    objectsCoordinates: Array,
    displaySticks: Boolean,
    width: Number,
    height: Number,
    lineWidth: Number,
    buttonDisplayText: String,
    buttonHideText: String,
    boxStyle: String,
  };

  connect() {
    this._redraw();
  }

  toggle(event){
    event.preventDefault();
    this.displaySticksValue = !this.displaySticksValue;
    if(this.displaySticksValue){
      event.target.innerText = this.buttonHideTextValue;
    } else {
      event.target.innerText = this.buttonDisplayTextValue;
    }
  }

  displaySticksValueChanged(){
    this._redraw();
  }

  _redraw(){
    if(this.displaySticksValue){
      this._setupCanvas();
      this._drawPoses();
      this._drawObjects();
    } else {
      this._clearCanvas();
    }
  }

  _setupCanvas() {
    var ctx = this.canvasTarget.getContext("2d");
    ctx.canvas.width = this.widthValue;
    ctx.canvas.height = this.heightValue;
    ctx.lineWidth = this.lineWidthValue;
  }

  _clearCanvas(){
    var ctx = this.canvasTarget.getContext("2d");
    ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
  }

  _drawPoses(){
    this.posesCoordinatesValue.forEach((coords) => {
      this._drawPoseSticks(coords["x"], coords["y"]);
    });
  }

  _drawObjects() {
    this.objectsCoordinatesValue.forEach((object) => {
      var ctx = this.canvasTarget.getContext("2d")
      ctx.beginPath()
      if (this.boxStyleValue == 'stroke-dasharray') {
        ctx.setLineDash([4, 4])
      }
      ctx.strokeStyle = "red"

      const scaledPoints = object.points.map((p) => {
        return {
          x: p.x * this.scaleRatioValue,
          y: p.y * this.scaleRatioValue,
        }
      })

      ctx.moveTo(scaledPoints[0].x, scaledPoints[0].y)
      ctx.lineTo(scaledPoints[1].x, scaledPoints[1].y)
      ctx.lineTo(scaledPoints[2].x, scaledPoints[2].y)
      ctx.lineTo(scaledPoints[3].x, scaledPoints[3].y)
      ctx.closePath()

      ctx.font = "16px Arial"
      ctx.fillStyle = "red"
      if (object.desc != undefined) {
        ctx.fillText(object.desc, scaledPoints[0].x + 8, scaledPoints[0].y + 16)
      }
      ctx.stroke()
    });
  }

  _drawPoseSticks(x, y) {
    var ctx = this.canvasTarget.getContext("2d");
    if (!x) {
      return;
    }
    x.forEach((item, index) => {
      ctx.fillStyle = "#005500";
      ctx.beginPath();
      ctx.arc(item * this.scaleRatioValue, y[index] * this.scaleRatioValue, 2, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fill();
      return ctx.closePath();
    });

    return this.CONNECTIONS.forEach((item, index) => {
      ctx.beginPath();
      ctx.strokeStyle = this.POSECOLORS[index];
      ctx.moveTo(x[item[0]] * this.scaleRatioValue, y[item[0]] * this.scaleRatioValue);
      ctx.lineTo(x[item[1]] * this.scaleRatioValue, y[item[1]] * this.scaleRatioValue);
      ctx.stroke();
      return ctx.closePath();
    });
  }
}
