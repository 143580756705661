import $ from "jquery";

export const decodeBluetoothResponse = (response, onboardDecodeUrl) => {
  const token = document.querySelector(
    'meta[name="csrf-token"]'
  ).content

  fetch(onboardDecodeUrl, {
    method: "PATCH",
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": "application/json"
    },
    credentials: "same-origin",
    body: JSON.stringify({
      bluetooth_response: response,
    })
  }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
};

export const notifyServerOnFactoryResetSuccess = (factoryResetUrl) => {
  $.ajax({
    data: {
      authenticity_token: window.settings.token,
      status: "success",
    },
    url: factoryResetUrl,
    type: "patch",
  });
};
