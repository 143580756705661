import { Controller } from '@hotwired/stimulus';

const discoveryInterval = 1000; // wait 1 second between each discovery attempt
const discoveryTimeout = 10; // bluetooth discovery timeout
const serverResponseTimeout = 30000;

export default class extends Controller {
  static targets = [ "peripherals" ];

  initialize() {
    this.selectedPeripheral = null;
  }

  connect() {
    this.currentDiscoverRequest = null;
    this.currentPairRequest = null;

    this.startDiscover(3);
  }

  disconnect(){
    this._cancel_discovery_request();
    this._cancel_pair_request();
  }

  pair(event) {
    event.preventDefault();

    if (!this.selectedPeripheral) {
      return;
    }

    $('#searching').html(this.data.get('busy-pairing'));
    $('#peripheral_selection').hide();
    this._cancel_discovery_request();

    this.startPairing(this.selectedPeripheral);
    return false;
  }

  blink() {
    $.ajax({
      url: '/peripherals/blink',
      type: 'post',
      data: {
        authenticity_token: settings.token,
        peripheral: {
          peripheral_type_id: this.data.get('peripheral-type-id'),
          device_id: this.data.get('device-id'),
          mac_address: this.selectedPeripheral,
        },
      },
    });
  }

  selectPeripheral(event) {
    this.selectedPeripheral = event.target.value;

    const blinkOnSelect = this.data.get('blink-on-select');
    if (blinkOnSelect === 'true') {
      this.blink(this.selectPeripheral);
    }
  }

  updatePeripherals(peripheralsView) {
    if (!$('#peripheral_selection').is(':visible')) {
      return;
    }

    $("#peripheral_selection").html(peripheralsView);

    // keep previously selected peripheral as checked
    if (this.selectedPeripheral !== null) {
      const selectedRadio = this.peripheralsTarget.querySelector(`input[value="${this.selectedPeripheral}"]`);
      if (selectedRadio !== null) {
        selectedRadio.checked = true;
      }
    }

    setTimeout(() => this.startDiscover(discoveryTimeout), discoveryInterval);
  }

  startDiscover(seconds) {
    this._cancel_discovery_request();
    Promise.resolve(
      this.currentDiscoverRequest = $.ajax({
        url: '/peripherals/discover',
        type: 'patch',
        data: {
          authenticity_token: settings.token,
          peripheral: {
            peripheral_type_id: this.data.get('peripheral-type-id'),
            device_id: this.data.get('device-id'),
            function: this.data.get('function'),
            function_option: this.data.get('function-option'),
          },
          timeout: seconds,
        },
        success: (response) => this.updatePeripherals(response.view),
        error: (_jqXHR, textStatus, _errorThrown) => {
          if (textStatus !== 'abort') {
            this._cancel_discovery_request();
            $.notify(this.data.get('problem-discovering'));
          }
        },
        timeout: serverResponseTimeout,
      })
    ).then(() => console.log('timeout happened'))
     .catch(
      (e) => {
        console.log(e);
        if (e.statusText === 'timeout') {
          $.notify(this.data.get('no-server-response'));
        }
      }
    );
  }

  startPairing(macAddress) {
    this._cancel_pair_request();
    Promise.resolve(
      this.currentPairRequest = $.ajax({
        url: '/peripherals/pair',
        type: 'patch',
        data: {
          authenticity_token: settings.token,
          housing_unit_id: this.data.get('housing-unit-id'),
          peripheral: {
            peripheral_type_id: this.data.get('peripheral-type-id'),
            device_id: this.data.get('device-id'),
            mac_address: macAddress,
            function: this.data.get('function'),
            function_option: this.data.get('function-option'),
          },
        },
        success: (response) => {
          Cookies.set('notification', response.notification);
          Cookies.set('forcerefresh', 'true');

          if (response.redirect !== undefined) {
            window.location.href = response.redirect;
          } else {
            $.notify(this.data.get('problem-pairing'));
            console.log(response);
          }
        },
        error: () => $.notify(this.data.get('problem-pairing')),
        timeout: serverResponseTimeout,
      }
      )
    ).then(
      () => console.log('timeout happened')
    ).catch(
      (e) => {
        console.log(e);
        if (e.statusText === 'timeout') {
          $.notify(this.data.get('no-server-response'));
          this._cancel_pair_request();
        }
      }
    );
  }

  _cancel_discovery_request() {
    if(!this.currentDiscoverRequest) { return; }

    console.log('cancel discovery request');
    this.currentDiscoverRequest.abort();
  }

  _cancel_pair_request() {
    if(!this.currentPairRequest) { return; }

    console.log('cancel pair request');
    this.currentPairRequest.abort();
  }
}
