import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  CONNECTIONS = [[1, 2], [1, 5], [2, 3], [3, 4], [5, 6], [6, 7], [1, 8], [8, 9], [9, 10], [1, 11], [11, 12], [12, 13], [1, 0], [0, 14], [14, 16], [0, 15], [15, 17]]

  POSECOLORS = ["rgb(255,0,85)", "rgb(255,0,0)", "rgb(255,85,0)", "rgb(255,170,0)", "rgb(255,255,0)",
    "rgb(170,255,0)", "rgb(85,255,0)", "rgb(0,255,0)", "rgb(0,255,85)", "rgb(0,255,170)",
    "rgb(0,255,255)", "rgb(0,170,255)", "rgb(0,85,255)", "rgb(0,0,255)", "rgb(255,0,170)",
    "rgb(170,0,255)", "rgb(255,0,255)", "rgb(85,0,255)"]

  static targets = [ "canvas", "noCaptureText" ]
  static values = {
    scaleRatio: Number,
    posesCoordinates: Array,
    canvasWidth: Number,
    canvasHeight: Number,
    lineWidth: Number,
    currentCameraId: Number,
    camera0: String,
    camera1: String,
    camera2: String,
    camera3: String,
    refreshPosesInterval: Number,
    posesUrl: String,
    deviceCaptureId: Number,
    refreshImagesInterval: Number,
    turboFrameName: String,
    serverErrorText: String,
    loadingText: String,
    latestActivityText: String,
  }

  initialize() {
    this.cameraMap = {
      "-1": "none",
      0: this.camera0Value,
      1: this.camera1Value,
      2: this.camera2Value,
      3: this.camera3Value,
    }
  }

  connect() {
    if (this.hasRefreshPosesIntervalValue) {
      this.startRefreshingPoses()
    }
    if (this.hasRefreshImagesIntervalValue) {
      this.startRefreshingImages()
    }
  }

  disconnect() {
    this.stopRefreshingPoses()
    this.stopRefreshingImages()
  }

  startRefreshingPoses() {
    this.refreshPosesTimer = setInterval(() => {
      this._load()
    }, this.refreshPosesIntervalValue)
  }

  stopRefreshingPoses() {
    if (this.refreshPosesTimer) {
      clearInterval(this.refreshPosesTimer)
    }
  }

  startRefreshingImages() {
    this.refreshImagesTimer = setInterval(() => {
      this._reloadTurboFrame()
    }, this.refreshImagesIntervalValue)
  }

  stopRefreshingImages() {
    if (this.refreshImagesTimer) {
      clearInterval(this.refreshImagesTimer)
    }
  }

  _reloadTurboFrame() {
    document.getElementById(this.turboFrameNameValue).reload()
  }

  _load() {
    this._set_loading_text()
    fetch(this.posesUrlValue)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      }).then(response => response.text())
      .then(jsonString => {
        var json = JSON.parse(jsonString)
        this.posesCoordinatesValue = JSON.parse(json.person_poses)
        this.currentCameraIdValue = json.camera_id
        this.deviceCaptureIdValue = json.device_capture_id
        this.latestActivityTextValue = json.latest_activity_text
      }).catch(error => {
        this.latestActivityTextValue = this.serverErrorTextValue
        console.log(error)
      })
  }

  _redraw(){
    this._clearCanvas()
    this._drawPoses()
  }

  _set_loading_text() {
    this.latestActivityTextValue = this.loadingTextValue
  }

  posesCoordinatesValueChanged() {
    this._redraw()
  }

  latestActivityTextValueChanged(){
    this.noCaptureTextTarget.innerHTML = this.latestActivityTextValue
  }

  currentCameraIdValueChanged() {
    var imageToDisplay = this.cameraMap[this.currentCameraIdValue]
    if (imageToDisplay == "none") {
      this.noCaptureTextTarget.hidden = false
      this.canvasTarget.style.backgroundImage = "none"
    } else {
      this.noCaptureTextTarget.hidden = true
      this.canvasTarget.style.backgroundImage = "url(" + imageToDisplay + ")"
    }
    this._redraw()
  }

  _clearCanvas(){
    var ctx = this.canvasTarget.getContext("2d")
    ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
  }

  _drawPoses(){
    var ctx = this.canvasTarget.getContext("2d")
    ctx.canvas.width = this.canvasWidthValue
    ctx.canvas.height = this.canvasHeightValue
    ctx.lineWidth = this.lineWidthValue
    this.posesCoordinatesValue.forEach((coords) => {
      this._drawPoseSticks(coords["x"], coords["y"])
    })
  }

  _drawPoseSticks(x, y) {
    var ctx = this.canvasTarget.getContext("2d")
    if (!x) {
      return
    }
    x.forEach((item, index) => {
      ctx.fillStyle = "#005500"
      ctx.beginPath()
      ctx.arc(item * this.scaleRatioValue, y[index] * this.scaleRatioValue, 2, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fill()
      return ctx.closePath()
    })

    return this.CONNECTIONS.forEach((item, index) => {
      ctx.beginPath()
      ctx.strokeStyle = this.POSECOLORS[index]
      ctx.moveTo(x[item[0]] * this.scaleRatioValue, y[item[0]] * this.scaleRatioValue)
      ctx.lineTo(x[item[1]] * this.scaleRatioValue, y[item[1]] * this.scaleRatioValue)
      ctx.stroke()
      return ctx.closePath()
    })
  }
}
