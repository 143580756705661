import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).select2({
      minimumResultsForSearch: -1,
      templateResult: this._templateFunction,
      templateSelection: this._templateFunction,
    })
  }
  _templateFunction(state) {
    if (!state.id) { return state.text; }
    return $(`<i class="${state.element.text.toLowerCase()}"></i>`)
  }
}
