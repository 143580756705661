import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["modalContent"]

  close() {
    this.#hideModal()
  }

  // hide modal when clicking outside of modal
  // action: "click@window->modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalContentTarget.contains(e.target)) {
      return
    }
    this.#hideModal()
  }

  #hideModal(){
    this.element.remove()
  }
}
