import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "poseOnlyModeDisabledInput",
    "poseOnlyModeEnabledInput",
    "sendNothingInput",
    "sendPosesInput",
    "sendImagesInput",
    "informedConsentRefusedHelperSection",
    "informedConsentAcceptedSection",
  ]

  handlePoseOnlyModeChange(event) {
    if ((event.target.value == "1") && (this.sendNothingInputTarget.checked)) {
      this.sendPosesInputTarget.checked = true
    }
  }

  handleWhatToSendOnFallOrPanicChange(event) {
    if ((event.target.value == "nothing") && (this.poseOnlyModeEnabledInputTarget.checked)) {
      this.poseOnlyModeDisabledInputTarget.checked = true
    }
  }

  handleCheckMateChange(event) {
    if ((event.target.value == "1") && (this.sendNothingInputTarget.checked)) {
      this.sendPosesInputTarget.checked = true
    }
  }

  informedConsentRefused(_event) {
    this.informedConsentRefusedHelperSectionTarget.hidden = false
    this.informedConsentAcceptedSectionTarget.hidden = true
  }

  informedConsentAccepted(_event) {
    this.informedConsentRefusedHelperSectionTarget.hidden = true
    this.informedConsentAcceptedSectionTarget.hidden = false
  }
}
