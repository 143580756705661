import { Controller } from "@hotwired/stimulus"
/**
 * Manages audio alerts for escalation events and monitoring events. It can play sounds immediately or
 * queue them for sequential playback.
 *
 * Features:
 * - Plays audio alerts when escalation events or monitoring events are detected
 * - Supports queueing of multiple alerts
 * - Supports debug logging
 * - Allows setting of volume
 *
 * Data Attributes:
 * - data-sound-dashboard-events-escalation-sound-url-value: Sound file URL for escalation events
 * - data-sound-dashboard-events-monitoring-sound-url-value: Sound file URL for monitoring events
 * - data-sound-dashboard-events-play-events-value: Boolean to enable/disable sound (true if enabled, defaults to true)
 * - data-sound-dashboard-events-use-queue-value: Enable or disable queueing of multiple alerts (defaults to true)
 * - data-sound-dashboard-events-debug-value: Enable or disable debug logging (defaults to false)
 * - data-sound-dashboard-events-volume-value: Number between 0 and 1 for audio volume (defaults to 1)
 *
 * Example usage:
 *
 * <div data-controller="sound-dashboard-events"
 *      data-sound-dashboard-events-escalation-sound-url-value="/assets/alert_sound.mp3"
 *      data-sound-dashboard-events-monitoring-sound-url-value="/assets/alert_sound.mp3"
 *      data-sound-dashboard-events-play-events-value="true"
 *      data-sound-dashboard-events-use-queue-value="true"
 *      data-sound-dashboard-events-debug-value="true"
 *      data-sound-dashboard-events-volume-value="0.5">
 * </div>
 *
 */
export default class extends Controller {
  static values = {
    escalationSoundUrl: String,
    monitoringSoundUrl: String,
    playEvents: { type: Boolean, default: true },
    useQueue: { type: Boolean, default: false },
    debug: { type: Boolean, default: false },
    volume: { type: Number, default: 1 }
  }

  static targets = [ "playAudio" ]

  connect() {
    this.queue = []
    this.isPlaying = false
    this.currentAudio = null

    this.#log(
      "Controller connected\n" +
      "Stimulus values:\n" +
      `  escalation sound url: ${this.escalationSoundUrlValue}\n` +
      `  monitoring sound url: ${this.monitoringSoundUrlValue}\n` +
      `  play events: ${this.playEventsValue}\n` +
      `  use queue: ${this.useQueueValue}\n` +
      `  debug: ${this.debugValue}\n` +
      `Queue size: ${this.queue.length}`
    )
  }

  playAudioTargetConnected(element) {
    if(this.playEventsValue) {
      const escalationEventId = element.dataset.escalationEventId
      const monitoringEventId = element.dataset.monitoringEventId

      if (escalationEventId) {
        this.#log("Escalation event detected")
        this.#processEscalationEvent(escalationEventId)
      } else if (monitoringEventId) {
        this.#log("Monitoring event detected")
        this.#processMonitoringEvent(monitoringEventId)
      } else {
        console.log("Neither escalationEventId nor monitoringEventId is present.")
      }
    } else {
      this.#log("Sound is disabled. Not processing events.")
    }
  }

  disconnect() {
    if(this.isPlaying) {
      this.currentAudio.pause()
    }
  }

  #processEscalationEvent(escalationEventId) {
    if (this.useQueueValue) {
      this.#enqueueAudio(this.escalationSoundUrlValue, escalationEventId)
    } else {
      this.#playAudio(this.escalationSoundUrlValue, escalationEventId)
    }
  }

  #processMonitoringEvent(monitoringEventId) {
    if (this.useQueueValue) {
      this.#enqueueAudio(this.monitoringSoundUrlValue, monitoringEventId)
    } else {
      this.#playAudio(this.monitoringSoundUrlValue, monitoringEventId)
    }
  }

  #enqueueAudio(url, eventId) {
    this.#log(`Escalation ID ${eventId}. Enqueuing audio, ulr: ${url}`)
    this.queue.push({ url: url, id: eventId })
    this.#processQueue()
  }

  async #processQueue() {
    if (this.isPlaying || !this.playEventsValue || this.queue.length === 0) return

    this.isPlaying = true

    while (this.queue.length > 0) {
      const { url, eventId } = this.queue.shift()

      try {
        await this.#playAudio(url, eventId)
      } catch (error) {
        this.#log(`Escalation ID ${eventId}. Error processing queue for : ${error.message}`)
      }
    }

    this.isPlaying = false
  }

  #playAudio(url, eventId) {
    return new Promise((resolve, reject) => {
      if (!this.playEventsValue) {
        resolve()
        return
      }

      this.#log("Attempting to play audio")

      const audio = new Audio(url)
      audio.preload = "auto"
      audio.volume = this.volumeValue

      audio.onerror = (error) => {
        this.#log(`Error with audio: ${error}`)
        reject(error)
      }
      audio.play().catch((error) => {
        this.#log(`Error playing audio: ${error}`)
        reject(error)
      })
      audio.onended = () => {
        this.#log("Audio finished playing")
        resolve()
      }

      this.currentAudio = audio
    })
  }

  #log(message) {
    if (this.debugValue) {
      console.log(message)
    }
  }
}
