import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["showPassword", "password"];

    connect() {
        this.showPasswordTarget.checked = false;
        this.togglePasswords();
    }

    togglePasswords() {
        for (let field of this.passwordTargets) {
            field.type = this.showPasswordTarget.checked ? "text" : "password";
        }
    }
}