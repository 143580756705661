import Rails from "@rails/ujs";

Rails.allowAction = function(link){
  if (link.data("confirm") == undefined){
    return true;
  }
  Rails.showConfirmationDialog(link);
  return false;
};

//User click confirm button
Rails.confirmed = function(link){
  link.data("confirm", null);
  link.trigger("click.rails");
};

//Display the confirmation dialog
Rails.showConfirmationDialog = function(link){
  var message = link.data("confirm");
  swal({
    title: message,
    type: "warning",
    confirmButtonText: window.I18n.answer_yes,
    confirmButtonColor: "#e04f1a",
    cancelButtonText: window.I18n.answer_no,
    showCancelButton: true
  })
    .then(function(e){
      if (e.value) Rails.confirmed(link);
    });
};
