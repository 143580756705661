import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [];

    hide(event) {
      let target = event.target;
      let timeout = target.dataset.msecHideTime || 2000;
      target.style.visibility = "hidden";
      setTimeout(() => { target.style.visibility = "visible"; }, timeout);
    }

}