import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['control', 'indicator', 'valueBasedWarningContainer']
  static values = {
    min: Number,
    max: Number,
    objectKey: String,
    preset: Number,
    step: Number,
    url: String,
    valueBasedWarningText: String,
    valueBasedWarningThreshold: Number
  }

  initialize() { }

  connect() {
    this.currentValue = this.presetValue
    this.drawControl(10, 150, this.presetValue)
  }

  send(value) {
    const data = {}
    data[this.objectKeyValue] = value
    $.ajax({
      url: this.urlValue,
      type: 'patch',
      data: data,
    })
  };

  controlChanged(elem){
    this.currentValue = elem.target.dataset.value;
  }

  controlUp() {
    if (this.currentValue <= (this.maxValue - this.stepValue)) {
      this.currentValue += this.stepValue
    }
  }

  controlDown() {
    if (this.currentValue >= (this.minValue + this.stepValue)) {
      this.currentValue -= this.stepValue
    }
  }

  get currentValue() {
    return parseInt(this.data.get('value'))
  }

  set currentValue(value) {
    value = parseInt(value)
    this.data.set('value', value)
    this.drawControl(10 , 150, value)
    this.send(value)
    this.presetValue = value
    if (this.valueBasedWarningThresholdValue && this.valueBasedWarningThresholdValue >= value){
      this.valueBasedWarningContainerTarget.innerHTML = ''
    } else {
      this.valueBasedWarningContainerTarget.innerHTML = this.valueBasedWarningTextValue
    }
  }

  drawControl(length, height, nowselected) {
    this.controlTarget.innerHTML = '';
    var i = length;
    for (i; i > 0; i--) {
      var margintop = Math.floor(height / 10) * (length - i);
      const altitude = height - margintop;
      if (margintop <= 0) {
        margintop = 0
      }
      if (i * this.stepValue <= nowselected) {
          this.controlTarget.innerHTML =
            `
             ${this.controlTarget.innerHTML}
             <div data-action="click->stepped-input#controlChanged"
                  data-value="${i * this.stepValue}"
                  style="background-color:#FFA300; height: ${altitude}px; margin-top: ${margintop}px;"
                  class="stepped-input-controller-bar"
             </div>
            `
      } else {
        this.controlTarget.innerHTML =
          `
           ${this.controlTarget.innerHTML}
           <div data-action="click->stepped-input#controlChanged"
                data-value="${i * this.stepValue}"
                style="height: ${altitude}px; margin-top: ${margintop}px;"
                class="stepped-input-controller-bar"
           </div>
          `
      }
    }
    this.indicatorTarget.innerHTML = `${nowselected}%`
  }
}
