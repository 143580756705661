import { Controller } from '@hotwired/stimulus'
// TODO remove this when all password fields uses reveal_password_controller.js
//
// this controller doesn't use the type=password to mask password but the CSS -webkit-text-security and thus will not trigger autocomplete for the field.
// use this controller if you don't want autocomplete to be triggered for the field.

export default class extends Controller {
    static targets = ["showPassword", "password"];

    connect() {
        this.showPasswordTarget.checked = false;
        this.togglePasswords();
    }

    togglePasswords() {
        for (let field of this.passwordTargets) {
            if (this.showPasswordTarget.checked) {
                field.style = "";
            } else {
                field.style = "-webkit-text-security: disc;";
            }
        }
    }
}
