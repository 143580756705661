import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "thresholdInput", "inputSection" ]
    static values = { immediately: Boolean }

    immediateThreshold(e) {
        this.immediatelyValue = true
        this.thresholdInputTargets.forEach((element, index) => {
            element.value = 0
        });
    }

    afterDurationThreshold(e) {
        this.immediatelyValue = false
    }

    immediatelyValueChanged() {
        this.inputSectionTarget.hidden = this.immediatelyValue
    }
}
