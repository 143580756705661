import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "tab" ]
  static classes = [ "active", "inactive" ]

  clicked({ params: { clickedTabIndex } }){
    this.tabTargets.forEach((tab, index) => {
      if (index == clickedTabIndex) {
        tab.classList.add(...this.activeClasses)
        tab.classList.remove(...this.inactiveClasses)
      } else {
        tab.classList.remove(...this.activeClasses)
        tab.classList.add(...this.inactiveClasses)
      }
    })
  }
}
