import { Controller } from '@hotwired/stimulus'
import { patch, destroy } from '@rails/request.js'
import isInsidePolygon from 'robust-point-in-polygon'

export default class extends Controller {
  LOW_OPACITY = 20
  NORMAL_OPACITY = 40

  static targets = ['canvas']
  static values = {
    zones: Array,
    cameraBoundaries: Object,
    width: Number,
    height: Number,
    ratio: Number,
    imageUrl: String,
    updateUrl: String,
    destroyUrl: String,
  }

  connect() {
    this._waiting()
    this.ctx = this.canvasTarget.getContext('2d')
    this.currentZoneType = this.currentColor = null
    this._setupCanvas()
    this._redraw()
    this._ready()
  }

  mouseClick(event) {
    event.preventDefault()

    if (this.waiting) return
    if (this.currentZoneType == null) {
      alert('Please select Zone Type above')
      return
    }

    this.currentPoints.push(this._limitedCurrentPoint(event))
    if (this.currentPoints.length <= 1) {
      this.isDrawing = true
      this._setBoundaries()
    }
  }

  mouseMove(event) {
    if (!this.isDrawing) return
    if (this.waiting) return

    this._drawObjects()
    this._drawPolygon(
      { points: [...this.currentPoints, this._limitedCurrentPoint(event)], color: this._setOpacity(this.currentColor, this.LOW_OPACITY), active: true }
    )
  }

  async dblClick(event) {
    event.preventDefault()

    if (this.waiting) return
    if (!this.isDrawing) return
    if (this.currentPoints.length < 3) return

    const newZone = {
      points: this._unscalePoints(this.currentPoints),
      zone_type: this.currentZoneType,
      camera: this.currentCamera,
      image_remote_url: this.imageUrlValue,
    }

    this._waiting()
    const response = await patch(
      this.updateUrlValue,
      { body: JSON.stringify({ ignored_zone: newZone } ) },
    )
    if (response.ok) {
      const responseBody = await response.json
      this.zonesValue = [...this.zonesValue, { ...responseBody, ...{ color: this.currentColor } }]
    } else {
      alert('Error Saving Zone')
    }
    this._redraw()
    this._ready()
  }

  async rightClick(event) {
    event.preventDefault()
    if (this.waiting) return

    const currentZone = this._selectedZone(this._unscalePoint(this._currentPoint(event)))
    if (currentZone) {
      this._waiting()
      if (window.confirm("Are you sure you want to delete this zone?")) {
        const response = await destroy(`${this.destroyUrlValue}?zone_id=${currentZone.id}`)
        if (response.ok) {
          this.zonesValue = this.zonesValue.filter(zone => zone.id !== currentZone.id)
        } else {
          alert('Error Deleting Zone')
        }
        this._redraw()
        this._ready()
      } else {
        this._ready()
      }
    } else {
      this._redraw()
    }
  }

  setZoneType(event) {
    if (this.waiting) return

    this.currentZoneType = event.params.zoneType
    this.currentColor = event.params.color
    this.element.querySelectorAll('[data-action="click->admin--ignored-zone#setZoneType"]').forEach(
      button => button.classList.remove('active')
    )
    event.target.classList.add('active')
  }

  _currentPoint(event) {
    const rect = this.canvasTarget.getBoundingClientRect()
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    }
  }

  _redraw(){
    this.currentPoints = []
    this.currentBoundaries = null
    this.currentCamera = null
    this.isDrawing = false
    this._drawObjects()
  }

  _setupCanvas() {
    this.ctx.canvas.width = this.widthValue
    this.ctx.canvas.height = this.heightValue
    this.ctx.lineWidth = 3
  }

  _drawObjects() {
    this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
    this.zonesValue.forEach(ignoredZone => {
      this._drawPolygon({ points: this._scalePoints(ignoredZone.points), color: this._setOpacity(ignoredZone.color, this.NORMAL_OPACITY) })
    })
  }

  _drawPolygon({ points = [], active = false, color }) {
    this.ctx.beginPath()
    this.ctx.moveTo(points[0].x, points[0].y)
    points.slice(1).forEach(point => {
      this.ctx.lineTo(point.x, point.y)
    })
    if (active) {
      this.ctx.lineWidth = 2
      this.ctx.strokeStyle = '#FF0F47AD'
      this.ctx.stroke()
    }
    if (points.length > 2) {
      this.ctx.closePath()
      this.ctx.fillStyle = color
      this.ctx.fill()
    }
  }

  _setOpacity(color, percentage) {
    const decimal = `0${Math.round(255 * (percentage / 100)).toString(16)}`.slice(-2).toUpperCase()
    return color + decimal
  }

  _waiting() {
    this.waiting = true
    this.element.classList.add('cursor-wait')
  }

  _ready() {
    this.waiting = false
    this.element.classList.remove('cursor-wait')
  }

  _selectedZone(point) {
    return this.zonesValue.find(
      (zone) => {
        return isInsidePolygon(zone.points.map(pp => [pp.x, pp.y]), [point.x, point.y]) < 0
      }
    )
  }

  _setBoundaries() {
    const point = this._unscalePoint(this.currentPoints[0])
    this.currentCamera = Object.keys(this.cameraBoundariesValue).map(e => parseInt(e)).find((camera) => {
      const boundaries = this.cameraBoundariesValue[camera]
      return point.x >= boundaries.x[0] && point.x < boundaries.x[1] &&
             point.y >= boundaries.y[0] && point.y < boundaries.y[1]
    })
    this.currentBoundaries = this.cameraBoundariesValue[this.currentCamera]
  }

  _limitedCurrentPoint(event) {
    return this._limitPointToBoundaries(this._currentPoint(event))
  }

  _limitPointToBoundaries(point) {
    if (this.currentBoundaries == null) return point

    return {
      x: Math.floor(
        Math.min(Math.max(this.currentBoundaries.x[0] * this.ratioValue, point.x), this.currentBoundaries.x[1] * this.ratioValue - 1)
      ),
      y: Math.floor(
        Math.min(Math.max(this.currentBoundaries.y[0] * this.ratioValue, point.y), this.currentBoundaries.y[1] * this.ratioValue - 1)
      ),
    }
  }

  _scalePoint(point) {
    return { x: point.x * this.ratioValue, y: point.y * this.ratioValue }
  }

  _unscalePoint(point) {
    return { x: point.x / this.ratioValue, y: point.y / this.ratioValue }
  }

  _scalePoints(points) {
    return points.map((point) => {
      return this._scalePoint(point)
    })
  }

  _unscalePoints(points) {
    return points.map((point) => {
      return this._unscalePoint(point)
    })
  }
}
