import { Controller } from "@hotwired/stimulus"

/**
 *  A Stimulus controller that plays an alert sound when a user selects an option from an HTML select element.
 *
 *  Expects a data-audio-file-path attribute on the options
 *
 *  <div data-controller="sound-select">
 *    <label>New monitoring event</label>
 *    <select data-action="change->sound-select#play">
 *      <option value="sound1" data-audio-file-path="/assets/sound1.mp3">Sound 1</option>
 *      <option value="sound2" data-audio-file-path="/assets/sound2.mp3">Sound 2</option>
 *    </select>
 *  </div>
 */
export default class extends Controller {
  connect() {
    this.audio = new Audio()
  }

  disconnect() {
    if (!this.audio.paused) {
      this.audio.pause()
    }
  }

  play(event) {
    this.stopAudio()
    const selectedOption = event.target.selectedOptions[0]
    this.audioFileValue = selectedOption.dataset.audioFilePath
    this.audio.src = this.audioFileValue
    this.audio.play()
  }

  stopAudio() {
    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
    }
  }
}
