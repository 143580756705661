export default function base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  let bytes = new Uint8Array(len);
  let i = 0;
  while (i < len) {
    bytes[i] = binary_string.charCodeAt(i);
    i++;
  }
  return bytes.buffer;
}
