import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "escalationImagesDisplayed" ];
  static values = {
    interval: { type: Number, default: 2 },
    url: String,
  };

  connect() {
    this.startTimer();
  }

  reloadFrame() {
    console.debug("Reloading frame started");
    fetch(this.urlValue)
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    console.debug("Reloading frame finished");
  }

  escalationImagesDisplayedTargetConnected(element) {
    console.debug("Escalation images displayed target connected started");
    this.stopTimer();
    this.intervalValue = 10;
    this.startTimer();
    console.debug("Escalation images displayed target connected finished");
  }

  startTimer() {
    console.debug("Start timer started");
    if (this.interval) { 
      console.debug("timer already started");
      return;
    }

    this.interval = setInterval(() => {
      this.reloadFrame();
    }, this.intervalValue * 1000);
    console.debug("Start timer finished");
  }

  stopTimer() {
    console.debug("Stop timer started");
    if (!this.interval) { return; }

    clearInterval(this.interval);
    this.interval = null;
    console.debug("Stop timer finished");
  }

  disconnect() {
    console.debug("Disconnect started");
    this.stopTimer();
    console.debug("Disconnect finished");
  }
}
